Number.prototype.formatMoney = function(c, d, t){
    var n = this,
        c = isNaN(c = Math.abs(c)) ? 2 : c,
        d = d == undefined ? "." : d,
        t = t == undefined ? "," : t,
        s = n < 0 ? "-" : "",
        i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
        j = (j = i.length) > 3 ? j % 3 : 0;
    return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
};

var wizard = {

    quotationId: 0,

    createNewQuotation: function () {
        $.post("quotation", {crea_nuova: true}, function (data) {
            if (data.response)
                app.href("quotation/" + data.message + "/wizard");
            else
                app.error(data.message);
        });
    },

    init: function (quotationId) {
        wizard.quotationId = quotationId;
    },

    back: function (step) {
        $("[data-interaction=control_buttons] button").attr("disabled", "disabled");
        var fnz = app.eval('my_back_' + step);
        if (typeof fnz == 'function') {
            var _params = fnz(function () {
                $("[data-interaction=control_buttons] button").removeAttr("disabled");
            });
        } else {
            bootbox.confirm({
                title: "Warning!",
                message:'Please note that unsaved changes will be lost. Continue?',
                callback: function(result){
                    if(result) {
                        app.href("quotation/" + wizard.quotationId + "/wizard?step=" + (step - 1));
                    }
                    $("[data-interaction=control_buttons] button").removeAttr("disabled");
                }
            });
        }
    },
    next: function (step) {
        $("[data-interaction=control_buttons] button").attr("disabled", "disabled");
        var fnz = app.eval('my_next_' + step);
        if (typeof fnz == 'function') {
            var _params = fnz(function () {
                $("[data-interaction=control_buttons] button").removeAttr("disabled");
            });
        }
    },
    cancel: function (step) {
        $("[data-interaction=control_buttons] button").attr("disabled", "disabled");
        var fnz = app.eval('my_cancel_' + step);
        if (typeof fnz == 'function') {
            var _params = fnz(function () {
                $("[data-interaction=control_buttons] button").removeAttr("disabled");
            });
        } else {
            app.href('quotation');
        }
    },
    delete: function (step, message) {
        $("[data-interaction=control_buttons] button").attr("disabled", "disabled");
        var fnz = app.eval('my_delete_' + step);
        if (typeof fnz == 'function') {
            var _params = fnz(function () {
                $("[data-interaction=control_buttons] button").removeAttr("disabled");
            });
        } else {
            if (!confirm(message)) {
                $("[data-interaction=control_buttons] button").removeAttr("disabled");
                return;
            }

            app.block(1);
            $.delete(app.baseUrl + "/quotation/" + wizard.quotationId, {})
                .done(function (data) {
                    if (data.response) {
                        app.success(data.message);
                        app.href('quotation');
                    } else {
                        app.warning(data.message);
                    }

                    app.block(0);
                })
                .fail(function (data) {
                    app.block(0);
                    app.error("", app.parseAjaxError(data));
                });
        }
    },
    logs: function () {
        $.get(app.baseUrl + "/quotation/" + wizard.quotationId + "/quotation_warning/logs")
            .done(function (data) {
                $("#logsModal").on("shown.bs.modal", function () {
                    $(this).find('.modal-body').html(data);
                });

                $("#logsModal").modal("show");
            });
    },
    parseError: function (error) {
        if (error && typeof error.responseJSON != 'undefined' && typeof error.responseJSON.errors != 'undefined') {
            switch (error.status) {
                case 422:
                    $.each(error.responseJSON.errors, function (i, error) {
                        app.error(error);
                    });
                    break;
                default:
                    app.error(error.responseJSON.message);
            }
        } else {
            app.error("Generic ajax error");
        }
    }

};