var user = function(userCrud) {

    this.bindEvents = function() {
        $("[data-interaction=reset]").unbind('click').bind('click', function () {
            if(confirm("Inviare il link per il reset della password?")) {
                var url = $(this).attr("data-url");

                app.block(1);
                $.post(url)
                    .success(function (data) {
                        if(data.response) {
                            app.success(data.message);
                        } else {
                            app.warning("", data.message);
                        }
                        app.block(0);
                    })
                    .error(function () {
                        app.block(0);
                        app.error("", "Ajax error!");
                    });
            }
        });

        $('[data-interaction=loginAs]').unbind('click').bind('click', function () {
            app.loginAs(this);
        });

        var drop = $("#sign").dropzone({
            autoProcessQueue: false,
            url: "#",
            headers: $.ajaxSetup().headers,
            addRemoveLinks: true,
            dictRemoveFile: $("#sign").attr('data-remove'),
            dictRemoveFileConfirmation: T.uploadRemove,
            maxFiles: 1,
            clickable: true,
            maxFilesize: $("#sign").attr('data-max'),
            acceptedFiles: '.jpg,.png',
            init: function () {
                var size = $("#sign").attr('data-size');
                var name = $("#sign").attr('data-name');

                var self = this;
                if ($.trim(size) !== "") {
                    // oggetto file per dropzone
                    var file = {
                        name: name,
                        size: size,
                        status: 'success',
                        accepted: true
                    };

                    // fingo di aggiungere un nuovo documento a dropzone
                    self.emit("addedfile", file);
                    self.createThumbnailFromUrl(file, app.siteUrl + "/user/" + $("#sign").attr('data-id') + "/sign");
                    self.emit("complete", file);

                    // aggiungo il mio file all'array
                    self.files.push(file);
                }
            },
            sending: function (file, data) {
                $("#spinner").show();
            },
            success: function (file, data) {
                $("#spinner").hide();
                if (!data.response) {
                    app.warning("", data.message);
                    this.removeFile(file);
                }
            },
            error: function (file, data) {
                // app.warning("", data);
                $("#spinner").hide();
                this.removeFile(file);
            },
            removedfile: function (file) {
                if (file.status === "success") {
                    $("#spinner").show();
                    $.delete(app.baseUrl + "/user/" + $("#sign").attr('data-id') + "/sign")
                        .done(function (data) {
                            $("#spinner").hide();

                            if (data.response) {
                                $(document).find(file.previewElement).remove();
                            } else {
                                app.warning("", data.message);
                            }
                        })
                        .fail(function () {
                            $("#spinner").hide();
                            app.error("", "Server error! Retry later!");
                        });
                } else {
                    $(document).find(file.previewElement).remove();
                }
            },
            maxfilesexceeded: function (file) {
                app.warning("", T.uploadMaxFilesExceeded);
                this.removeFile(file);
            }
        });

        if (userCrud) {
            userCrud.getCustomFormData = function (data) {
                for (var i = 0; i < drop[0].dropzone.files.length; i++) {
                    if (drop[0].dropzone.files[i].status === 'queued') {
                        data.append('sign', drop[0].dropzone.files[i], drop[0].dropzone.files[i].name);
                    }
                }

                return data;
            };
        }
    };

    this.bindEvents();

};
