var quotation_warning = function () {
    var quotation_warning = this;

    this.done = function (data) {
        app.block(0);

        if (data.response) {
            app.reload();
        } else {
            app.warning(data.message);
        }
    };

    this.fail = function (data) {

    };

    this.bindEvents = function () {
        $('[data-interaction="manage-warning"]').unbind('click').bind('click', function () {
            var id = parseInt($(this).attr('data-id'));
            var status = parseInt($(this).attr('data-status'));
            var notes = "";

            if (status === 30) {
                notes = prompt(T.warningNotes);
            }

            app.block(1);
            $.put(app.baseUrl + "/quotation_warning/" + id, {
                op: "inline",
                status: status,
                notes: notes
            })
                .done(function (data) {
                    quotation_warning.done(data);
                })
                .fail(function (data) {
                    quotation_warning.fail(data);
                });
        });
    };
};