var app_websocket = function (pusher_endpoint) {

    var app_websocket = this;
    this.echo = null;

    if (typeof io != 'undefined') {

        this.echo = new Echo({
            broadcaster: 'socket.io',
            host: pusher_endpoint
        });

        // NB: il nome dell'evento deve essere preceduto da un .
        this.echo.channel("wsps")
            .listen('.' + app.user.id + '-MachineDocument2HtmlCompleted', function (data) {
                $("#tblMachineDocument_default_" + data.machine_id).data("dataTable").redrawPage();
            });

    }
}