var component_rules = function (dataTable) {

    var component_rules = this;

    this.refreshComponentRules = function (componentid) {

        $.get("component/" + componentid + "/rules", {
            rule_set_id: $("#frmRuleSet").attr('data-id')
        }, function (data) {
            $("#components_rules").find("[data-componentid=" + componentid + "]").find("[data-interaction=rule_definition_container]").html(data);

            component_rules.bindEvents();
            app.runBind();

            app.blockUI(false);
        }).error(function () {
            app.blockUI(false);
        });
    };

    this.refreshRule = function (ruleid) {
        $.each(component_rules.getComponentIdInRule(ruleid), function (index, obj) {
            component_rules.refreshComponentRules(obj);
        });
        app.blockUI(false);
    };

    /**
     * Aggiunge un componenteid tra quelli relativi alla route
     *
     * @param ruleid
     * @param componentid
     */
    this.addComponentInRule = function (ruleid, componentid) {
        var obj_rule = $("[data-interaction=rule][data-ruleid=" + ruleid + "]");
        var components_in_rule = obj_rule.data("components_in_rule") || '';

        if (components_in_rule.indexOf("|" + componentid + "|") === -1) {
            components_in_rule += ((components_in_rule !== '' ? "_" : '') + ("|" + componentid + "|"));
        }

        obj_rule.data("components_in_rule", components_in_rule);
    };

    /**
     * Restituisce i componenti id relativi alla route
     * @param ruleid
     */
    this.getComponentIdInRule = function (ruleid) {

        if(!ruleid) {
            return [];
        }

        var obj_rule = $("[data-interaction=rule][data-ruleid=" + ruleid + "]");
        var components_in_rule = obj_rule.data("components_in_rule").split("_") || [];

        var ret = [];
        for (var i = 0; i < components_in_rule.length; i++) {
            ret.push(components_in_rule[i].split("|").join(""));
        }

        return ret;
    };

    this.bindEvents = function () {

        /**
         * Salvataggio per ogni regola dei componenti che ne fan parte in modo che alla modifica della regola
         * sia possibile aggiornare i componenti correlati
         */
        $("[data-interaction=rule]").each(function (index, obj_rule) {
            $.each($(obj_rule).find("[data-ruledetailid]"), function (index, obj_rule_detail) {
                var componentid = $(obj_rule_detail).parents("tr[data-componentid]").attr("data-componentid");
                component_rules.addComponentInRule($(obj_rule).attr('data-ruleid'), componentid);

            });

            // console.log(component_rules.getComponentIdInRule($(obj_rule).attr('data-ruleid')));

        });

        $("[data-interaction=change_component_section]").unbind("change").bind("change", function () {
            var ruleid = $(this).parents("tr[data-ruleid]").attr("data-ruleid");
            var componentid = $(this).parents("tr[data-componentid]").attr("data-componentid");
            var machineid = $(this).parents("tr[data-componentid]").attr("data-machineid");

            app.blockUI(true);
            $.post("component_section/set_component_machine", {
                componentid: componentid,
                machineid: machineid,
                section: $(this).val()
            }, function (data) {
                if (data.response) {
                    component_rules.refreshRule(ruleid);
                }
                app.blockUI(false);
            }).error(function(){
                app.blockUI(false);
            });
        });

        $("[data-interaction=change_rule_type]").unbind("change").bind("change", function () {
            var ruleid = $(this).parents("tr[data-ruleid]").attr("data-ruleid");

            app.blockUI(true);
            $.put("rule/" + ruleid, {
                type: $(this).val()
            }, function (data) {
                if (data.response) {
                    component_rules.refreshRule(ruleid);
                }
            });

        });


        $("[data-interaction=remove_rule_detail]").unbind("click").bind("click", function () {
            var ruleid = $(this).parents("tr[data-ruleid]").attr("data-ruleid");
            var ruledetailid = $(this).parents("tr[data-interaction=rule_detail]").attr("data-ruledetailid");

            app.blockUI(true);
            $.delete("rule_detail/" + ruledetailid, function (data) {
                if (data.response) {
                    component_rules.refreshRule(ruleid);
                } else {
                    app.warning(data.message);
                }
            });

        });

        $("[data-interaction=add_rule_detail]").unbind("click").bind("click", function () {
            var componentid = $(this).parents("tr[data-componentid]").attr("data-componentid");
            var ruleid = $(this).parents("tr[data-ruleid]").attr("data-ruleid");

            app.blockUI(true);
            $.post("rule_detail", {
                rule_id: ruleid,
                component_id: componentid
            }, function (data) {
                if (data.response) {
                    component_rules.refreshRule(ruleid);
                }
            });

        });

        $("[data-interaction=rule_detail_attribute]").unbind("change").bind("change", function () {
            var ruledetailid = $(this).parents("[data-interaction=rule_detail]").attr("data-ruledetailid");

            var quantity_check = $("[data-interaction=rule_detail][data-ruledetailid=" + ruledetailid + "]").find('[name=quantity_check]').is(":checked");
            var minimum_quantity = $("[data-interaction=rule_detail][data-ruledetailid=" + ruledetailid + "]").find('[name=minimum_quantity]').val() || 0;
            var maximum_quantity = $("[data-interaction=rule_detail][data-ruledetailid=" + ruledetailid + "]").find('[name=maximum_quantity]').val() || 0;
            var component_selected = $("[data-interaction=rule_detail][data-ruledetailid=" + ruledetailid + "]").find('[name=component]').val() || 0;
            var ghost = $("[data-interaction=rule_detail][data-ruledetailid=" + ruledetailid + "]").find('[name=ghost]').is(":checked");
            var optional = $("[data-interaction=rule_detail][data-ruledetailid=" + ruledetailid + "]").find('[name=optional]').is(":checked");
            var available_as_standalone = $("[data-interaction=rule_detail][data-ruledetailid=" + ruledetailid + "]").find('[name=available_as_standalone]').is(":checked");

            if (component_selected == 0)
                component_selected = $(this).parents("[data-componentid]").attr('data-componentid') || 0;

            app.blockUI(true);
            $.put("rule_detail/" + ruledetailid, {
                quantity_check: quantity_check,
                minimum_quantity: minimum_quantity,
                maximum_quantity: maximum_quantity,
                component_id: component_selected != 0 ? component_selected : null,
                ghost: ghost,
                optional: optional,
                available_as_standalone: available_as_standalone,
            }, function (data) {
                if (data.response) {
                    //  component_rules.refreshRule(ruleid);
                } else {
                    app.error(data.message);
                }

                app.blockUI(false);
            }).error(function(){
                app.blockUI(false);
                app.error('ERROR');
            });

        });

        $("[data-interaction=remove_rule]").unbind("click").bind("click", function () {
            var ruleid = $(this).parents("tr[data-ruleid]").attr("data-ruleid");

            app.block(1);
            $.delete("rule/" + ruleid, function (data) {
                if (data.response) {
                    component_rules.refreshRule(ruleid);
                } else {
                    app.warning(data.message);
                }
            });
        });


        $("[data-interaction=add_rule]").unbind("click").bind("click", function () {
            var componentid = $(this).parents("tr[data-componentid]").attr("data-componentid");
            // var machineid = $(this).parents("tr[data-componentid]").attr("data-machineid");
            var ruleSetId = $("#frmRuleSet").attr('data-id');

            app.blockUI(true);
            $.post("rule_detail", {
                rule_set_id: ruleSetId,
                component_id: componentid
            }, function (data) {
                if (data.response) {
                    component_rules.refreshComponentRules(componentid);
                }
            });
        });

        $("[data-interaction=costruisci_regola_depends_on]").unbind("click").bind("click", function () {
            var componentid = $(this).parents("tr[data-componentid]").attr("data-componentid");
            var machineid = $(this).parents("tr[data-componentid]").attr("data-machineid");

            var ruledetailid = $(this).attr("data-ruledetailid");

            // Regole base
            var rules_basic = {
                condition: 'AND',
                rules: [
                    {
                        id: 'component',
                        operator: 'equal',
                        value: componentid,
                        // readonly: true,
                        // no_delete: true
                    }
                ]
            };

            // Recupero i componenti depends on relativi al componente selezionato
            $.get("component/" + componentid + "/get_depends_on_components", {
                machine_id: machineid
            }, function (data_depon) {

                var components = {};
                if(!data_depon.response){
                    app.error(data_depon.message);
                    return;
                }else{
                    components = data_depon.message;
                }

                // Recupero la boolean expression su DB per la regola
                $.get("rule_detail/" + ruledetailid + "/get_boolean_expression", function (data) {

                    $('#myModal').modal('show');

                    $('#myModal .btn-primary').unbind("click").bind("click", function () {

                        var result = $('#myModal #query_builder').queryBuilder('getRules');

                        var boolean_expression = '';
                        if (!$.isEmptyObject(result)) {
                            boolean_expression = JSON.stringify(result);

                            app.blockUI(true);
                            $.put("rule_detail/" + ruledetailid, {
                                boolean_expression: boolean_expression
                            }, function (data) {
                                if (!data.response) {
                                    app.error(data.message);
                                }
                                $('#myModal .close').click();

                                component_rules.refreshComponentRules(componentid);
                            });
                        } else {
                            app.error("Invalid expression");
                        }

                    });

                    $('#myModal').on('shown.bs.modal', function (e) {
                        $('#myModal .modal-body').html('<div id="query_builder"></div>');

                        $('#myModal #query_builder').queryBuilder({
                            plugins: ['bt-tooltip-errors'],

                            // operators: $.fn.queryBuilder.constructor.DEFAULTS.operators.concat([
                            //     {type: 'contains_one', nb_inputs: 2, multiple: true, apply_to: ['string']}
                            // ]),

                            filters: [
                                {
                                    id: 'component',
                                    label: 'Component',
                                    type: 'integer',
                                    input: 'select',
                                    values: components,
                                    operators: [
                                        'equal',
                                        'not_equal'
                                    ]
                                }
                            ],

                            rules: data.response ? JSON.parse(data.message) : rules_basic
                        });
                    });
                });
            });

        });

        $('[data-interaction=activate]').unbind('click').bind('click', function () {
            var id = $("#frmRuleSet").attr('data-id');

            app.block(1);
            $.put(app.baseUrl + "/machine/" + $("#machine-id").val() + "/rule_set/" + id, {
                op: 'inline',
                active: true
            })
                .done(function (data) {
                    if (data.response) {
                        app.reload();
                    } else {
                        app.warning("", data.message);
                    }

                    app.block(0);
                })
                .fail(function (data) {
                    app.block(0);
                    app.error("", app.parseAjaxError(data));
                });
        });
    }
};